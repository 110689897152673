<template>
    <div v-if="checkData" class="shadow-md relative rounded-2xl bg-card-bg flex gap-6 flex-col text-base-content">
        <div class="p-3 panel-head bg-primary-300 font-bold flex gap-3 justify-between items-center rounded-t-2xl">
            Form Details
            <button v-allow="'form.delete'" @click="deleteCheck(checkData)" class="flex btn btn-outline bg-red-500 text-white-text btn-circle hover:bg-red-600 border-none btn-sm">
                <font-awesome-icon icon="trash" />
            </button>
        </div>
        <div class="flex flex-col gap-3 p-3">
            <div class="flex w-full gap-6 items-stretch">
                <div class="flex flex-col w-2/5 gap-3">
                    <div class="flex flex-col gap-3 w-full">
                        <span class="text-md font-bold"> Form Name </span>
                        <Input :disabled="!checkPermission('form.edit')" inputClass="bg-gray-100 border-0 rounded-xl" class="h-10 w-full" placeholder="Field Name" v-model="checkData.name" />
                    </div>
                    <!-- <div class="flex flex-col gap-3">
                        <span class="text-gray-700 text-md font-bold"> SLA Period</span>
                        <div class="flex items-center gap-5">
                            <Input inputClass="bg-gray-100 border-0 rounded-xl" @keypress="isNumber" type="number" class="h-10 w-full" placeholder="Field Name" v-model="checkData.sla" />
                            <span class="text-gray-700 text-md font-semibold w-1/4">Days</span>
                        </div>
                    </div> -->
                </div>
                <div class="flex flex-col gap-3 w-3/5">
                    <span class="text-md font-bold"> Form Description </span>
                    <textarea :disabled="!checkPermission('form.edit')" v-model="checkData.description" class="rounded-xl bg-gray-100 max-w-full textarea textarea-bordered focus:textarea-primary overflow-hidden p-3 flex-grow border-0" placeholder="Description" id="" cols="20"></textarea>
                </div>
            </div>
            <div v-if="checkData && checkData.parameters && checkData.parameters.parameters">
                <span class="text-md font-bold"> Check Parameters </span>
                <div class="mt-2">
                    <div class="rounded flex gap-2 items-center" v-for="params in checkData.parameters.parameters" :key="params.name">
                        <div class="bg-indigo-200 py-1 px-2 rounded">
                            {{ params.name }}
                        </div>
                        <input :disabled="!checkPermission('form.edit')" min="0" :type="params.value_type" @keypress="isNumber" class="text-center border border-b border-gray-200 h-8 w-12 rounded flex items-center justify-center" v-model="params.value" />
                    </div>
                </div>
            </div>
            <div :class="{notAllowded : !notAllowdedHere}">
                <span class="text-md font-bold ml-4"> Read Only </span>
                <div class="mt-5">
                    <div class="flex items-center ml-4">
                        <span class="toggle-label-btn pr-1">Disable</span>
                        <div class="toggle-btn-cnt">
                            <label class="switch">
                                <input type="checkbox" @click="handleToggle"  :checked="checkData.read_access_only" :disabled="!notAllowdedHere">
                                <span class="slider round" :class="{notAllowded : !notAllowdedHere}"></span>
                            </label>
                        </div>
                        <span class="toggle-label-btn">Enable</span>
                    </div>
                </div>
            </div>
            <div class="mt-2 flex justify-end max-w-full gap-4" v-allow="'form.edit'" >
                <Button @click="saveCheck()" class="btn-primary float-right mt-4" text="Save" />
            </div>
        </div>
    </div>
</template>

<script>
import Input from "@/components/input";
import Button from "@/components/button";
import { isNumber } from "@/plugins/functions";
import { checkPermission } from "@shared/utils/functions"

export default {
    name: "check-details",
    components: {
        Input,
        Button,
    },
    data(){
        return{
            notAllowdedHere:true
        };
    },
    props: {
        checkData: {
            type: Object,
            default: null,
        },
        handleReadAccessMethod:{
            type: Function,
            default: ()=>{}
        }
    },
    mounted(){
        this.notAllowdedHere = checkPermission('form.edit')
    },
    methods: {
        checkPermission,
        isNumber,
        saveCheck() {
            this.$emit("handleSaveCheck");
        },
        deleteCheck(checkDetails) {
            this.$emit("handleDeleteCheck", checkDetails);
        },
        handleToggle(e){
            this.checkData.read_access_only = e.target.checked
            this.handleReadAccessMethod(this.checkData.id , e.target.checked)
            return this.checkData.read_access_only
        }
    },
};
</script>

<style lang="scss" scoped>
  .switch {
        position: relative;
        display: inline-block;
    }

    .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        width: 44px;
        height: 21px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 17px;
        width: 17px;
        left: 5px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

    .toggle-btn-cnt{
        display: flex;
        align-items: center;
        width: 49px;
    }
    .toggle-label-btn{
        font-size: 13px !important;
    }
    .notAllowded{
        cursor: not-allowed! important;
    }
</style>
