<template>
  <div class="roleBlock flex gap-2.5">
    <div class="roleBlock__item">
      <Checkbox
        @click.native.stop
        :disabled="role.role_write || role.role_mandatory || this.$route.params.action == 'view'"
        v-model="role_read"
      />
    </div>
    <div class="roleBlock__item">
      <Checkbox
        @click.native.stop
        :disabled="role.role_mandatory || this.$route.params.action == 'view'"
        v-model="role_write"
      />
    </div>
    <div class="roleBlock__item">
      <Checkbox
        @click.native.stop
        :disabled="role.role_write || role.role_mandatory || role.role_read || this.$route.params.action == 'view'"
        v-model="role_visible"
      />
    </div>
    <div class="roleBlock__item">
      <Checkbox
        @click.native.stop
        v-model="role_mandatory"
        :disabled="this.$route.params.action == 'view' || this.field.ui_label=='Label (info text)'"
      />
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/checkbox-alt";

export default {
  props: {
    role: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Checkbox,
  },
  computed: {
    role_read: {
      get() {
        return this.role.role_read;
      },
      set(val) {
        this.changeRole('role_read', val);
      }
    },
    role_write: {
      get() {
        return this.role.role_write;
      },
      set(val) {
        this.changeRole('role_write', val);
      }
    },
    role_visible: {
      get() {
        return this.role.role_visible;
      },
      set(val) {
        this.changeRole('role_visible', val);
      }
    },
    role_mandatory: {
      get() {
        return this.role.role_mandatory;
      },
      set(val) {
        this.changeRole('role_mandatory', val);
      }
    },
  },
  methods: {
    changeRole(type, val) {
      const roleBackup = {...this.role};
      this.role[type] = val;

       if (this.role.role_mandatory) {
          this.role.role_read = true;
          this.role.role_write = true;
          this.role.role_visible = true;
          this.role.select_all = true;
      } else if (this.role.role_write) {
          this.role.role_read = true;
          this.role.role_visible = true;
          this.role.select_all = false;
      } else if (this.role.role_read) {
          this.role.role_visible = true;
          this.role.select_all = false;
      }
      this.$emit("PermissionChange", this.role);
      this.$emit("change", {role: this.role, backup: roleBackup});
      this.$emit("AgaincascadeClicked")
    },
  },
}
</script>

<style lang="scss" scoped>
.roleBlock {
  &__item {
    display: flex;
    align-items: center;
  }
}
</style>